import isNaN from 'lodash/isNaN'

export default function RoundToNearest(number, nearest) {
  if (isNaN(number)) {
    throw new Error('Number is not a number')
  }

  const times = Math.ceil(number / nearest)

  return nearest * times
}
