import {useEffect, useState} from 'react'
import {useContext} from 'react'
import isServerSide from '@helpers/misc/isServerSide'
import MobileOrDesktopContext from '@providers/MobileOrDesktopContext'

export const DEFAULT_DESKTOP_WIDTH = 1920
export const DEFAULT_MOBILE_WIDTH = 360

export default function useWidth() {
  const ssrMobileOrDesktop = useContext(MobileOrDesktopContext)
  const ssrWidth = ssrMobileOrDesktop === 'mobile' ? DEFAULT_MOBILE_WIDTH : DEFAULT_DESKTOP_WIDTH

  const [width, setWidth] = useState(isServerSide() ? ssrWidth : window.innerWidth)

  useEffect(() => {
    const handler = function () {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return width ?? ssrWidth
}
