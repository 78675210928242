import hasWebpSupport from '@helpers/misc/hasWebpSupport'
import isServerSide from '@helpers/misc/isServerSide'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import clone from 'lodash/clone'

import {localStorageGetItem} from './useLocalStorageState'

export default function useResizedImageURL(opt, queryOpts = {}) {
  const options = clone(opt)

  if (options.format !== 'jpeg' && hasWebpSupport()) {
    options.format = 'webp'
  }

  const cacheKey = `resizedImage.${JSON.stringify(options)}`
  const cachedResult = isServerSide() ? null : localStorageGetItem(cacheKey)

  const {image} = useApolloQuery({
    query: gql`
      query getResizedImage(
        $fileId: ID
        $scale: Float
        $width: Float
        $height: Float
        $resizeMode: ID
        $background: String
        $format: String
      ) {
        image(fileId: $fileId) {
          _id
          resizedURL(
            scale: $scale
            width: $width
            height: $height
            resizeMode: $resizeMode
            background: $background
            format: $format
          )
        }
      }
    `,
    variables: options,
    omit: !!cachedResult || !options.fileId || isServerSide(),
    ...queryOpts
  })

  if (!options.fileId) return null

  if (cachedResult) return cachedResult

  if (!image) return null

  localStorage?.setItem(cacheKey, image.resizedURL)

  return image.resizedURL
}
