import React from 'react'
import roundToNearest from '@helpers/misc/roundToNearest'
import useResizedImageURL from '@hooks/useResizedImageURL'

import styles from './styles.module.css'

export default function Image(props) {
  const {item, width} = props

  const url = useResizedImageURL(
    {
      fileId: item.image._id,
      width: roundToNearest(width * 2, 200) || 400,
      height: roundToNearest(width * 2, 200) || 400
    },
    {partial: true}
  )

  return (
    <img
      src={url}
      alt={item.title || item.description}
      title={item.title || item.description}
      className={styles.image}
    />
  )
}
