import React from 'react'
import scrollTo from '@helpers/misc/scrollTo'
import Link from 'next/link'

export default function InteligentLink(props) {
  const {moveScrollTo} = props
  const href = props.to ?? props.href
  const handledClick = () => {
    if (moveScrollTo) scrollTo({element: moveScrollTo})
  }

  if (href.startsWith('/')) {
    return (
      <Link href={href} passHref>
        <a style={props.style} className={props.className} onClick={handledClick}>
          {props.children}
        </a>
      </Link>
    )
  } else {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={props.style}
        className={props.className}
        onClick={handledClick}>
        {props.children}
      </a>
    )
  }
}
